<template>
  <div>
    <Form label-position="left" :label-width="150">
      <div style="margin: 0 10px;width:60%">
        <FormItem>
          <span slot="label" class="validate">接收群:</span>
          <div :class="isTrue ?'click-div-bold':'click-div'" @click="openGroup">{{groupName}}</div>
        </FormItem>
        <FormItem>
          <span slot="label" class="validate">公告内容:</span>
          <Input
            v-model="content"
            placeholder="请填写内容，限400字"
            type="textarea"
            :rows="10"
            maxlength="400"
          ></Input>
        </FormItem>
      </div>
    </Form>
    <LiefengModal
      title="选择接收群"
      :value="sendGroupStatus"
      @input="sendGroupStatusfn"
      class="group"
      :fullscreen="false"
    >
      <template v-slot:contentarea class="table-op">
        <div class="btn-div">
          <Button type="primary" @click="allChange" style="margin-right:10px">全选</Button>
          <!-- <Button type="info" @click="changeNone">反选</Button> -->
        </div>
        <div class="table-div">
          <!-- <Form
            class="selecForm"
            label-position="left"
            style="
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
              "
          > -->
            <CheckboxGroup style="display:flex;justify-content:flex-start;flex-wrap:wrap;margin:10px;" v-model="selectGroupList" @on-change="changeCheck">
                <Checkbox :label="item.groupId" v-for="item in manageList" :key="item.groupId" style="width:30%;padding:10px 0">{{item.groupName}}</Checkbox>
            </CheckboxGroup>
            <!-- <FormItem style="width: 30%;" v-for="(item, index) in manageList" :key="index">
              <Checkbox v-model="item.status" @on-change="changeCheck">{{ item.groupName }}</Checkbox>
            </FormItem> -->
          <!-- </Form> -->
        </div>
        <div>
          <Page
            :pageSize="userpageSize"
            :pageNum="usercurPage"
            :total="usertotal"
            @on-change="getUserPage"
            class="page-select"
          ></Page>
        </div>
      </template>
      <template v-slot:toolsbar>
        <Button
          type="info"
          style="margin-right: 10px"
          @click="cancelsendGroupNoticeTip = true;sendGroupStatusfn(false)"
        >取消</Button>
        <Button type="primary" @click="saveSendGroupNotice">确定</Button>
      </template>
    </LiefengModal>
  </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";
export default {
  data() {
    return {
      sendGroupStatus: false,
      cancelsendGroupNoticeTip: false,
      userpageSize: 20,
      usercurPage: 1,
      usertotal: 0,
      manageList: [],
      selectList: [],
      content: "",
      groupName: "点击这里选择群范围",
      isTrue: false,
      selectGroupList:[],
      selectNameList:[]
    };
  },
  methods: {
    //    修改事件
    changeCheck(val){
     
        //   this.manageList.map(item =>{  
        //     if(val.join(',').indexOf(item.groupId) > -1){
        //         this.selectNameList.push(item.groupName)
        //     } 
        //  }) 
    },
    //   点击反选按钮
    changeNone() {
      let List = [];
      this.manageList.map(item => {
        if (item.status == true) {
          item.status = false;
        } else {
          item.status = true;
        }
      });
    },
    //   点击全选按钮
    allChange() {
      if(this.manageList.length != 0){
        this.manageList.map(item =>{
          this.selectGroupList.push(item.groupId)
          this.selectNameList.push(item.groupName)
        })
      }
      this.selectGroupList = Array.from(new Set(this.selectGroupList))
      this.selectNameList = Array.from(new Set(this.selectNameList))
      // this.manageList.map(item => {
      //   item.status = true;
      // });
    },
    //   切换分页
    getUserPage(data) {
      this.getList({
        page: data,
        pageSize: this.userpageSize
      });
    },
    //   点击接收群div
    openGroup() {
      this.sendGroupStatus = true;
    
    },
    //   接受群模态框关闭事件
    sendGroupStatusfn(status) {
      if (!status && this.cancelsendGroupNoticeTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.sendGroupStatus = status;
            this.manageList.map(item => {
              item.status = false;
            });
            this.groupName = "点击这里选择群范围";
            this.isTrue = false;
            this.selectGroupList=[],
            this.selectNameList=[]
          }
        });
      } else {
        this.sendGroupStatus = status;
        this.cancelsendGroupNoticeTip = false;
      }
    },
    //   模态框确认按钮
    saveSendGroupNotice() {
      // this.selectGroupList = []
      let list = this.selectGroupList;
      // this.selectList = [];
      // this.manageList.map(item => {
      //   if (item.status == true) {
      //     list.push(item);
      //   }
      // });
      //   如果一个都没选
      if (list.length == 0) {
        this.$Message.warning({
          content: "请选择接收群",
          background: true
        });
        return;
      } else {
        // 有选项跑这里
        let nameList = [];
        list.map(item => {
          this.selectList.push(item.groupId);
          nameList.push(item.groupName);
        });
        // this.groupName = nameList.join(",");
        this.sendGroupStatus = false;
        this.cancelsendGroupNoticeTip = false;
        this.isTrue = true;
        this.selectGroupList = Array.from(new Set(this.selectGroupList))
        // this.selectNameList = Array.from(new Set(this.selectNameList))
        this.groupName = '已选择接收群';  
      }
    },
    // 接口部分
    getList(data) {
      this.$get("/sendmsg/pc/chat/groupCreate/queryPage", {
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        groupName: "",
        groupType: "",
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        oemCode: parent.vue.oemInfo.oemCode,
        pageSize: data.pageSize,
        page: data.page
      }).then(res => {
        let data = res.dataList;
        data.map(item => {
          item.status = false;
        });
        this.usertotal = res.maxCount
        this.manageList = data;
      });
    }
  },

  components: {
    LiefengModal
  }
};
</script>

<style scoped lang="less">
.click-div {
  width: 100%;
  height: 40px;
  color: #ccc;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  padding-left: 9px;
  line-height: 40px;
  user-select: none;
  cursor: pointer;
  &-bold {
    width: 100%;
    height: 40px;
    color: #515a6e;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    padding-left: 9px;
    line-height: 40px;
    user-select: none;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    border: 1px solid #57a3f3;
  }
}
.group {
  width: 600px;
  /deep/.ivu-modal-close {
    display: none;
  }
  .table-div {
    height: 400px;
    overflow-y: scroll;
    // position: relative;
  }
}
.table-op {
  position: relative;
  .btn-div {
    position: absolute;
    top: 0;
    left: 0;
  }
  .page-select {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>