<template>
    <div>
        <Form :model="formData" label-position="left" :label-width="150" :rules="ruleForm">
            <div style="margin: 0 10px;width:60%">
                <FormItem prop="name">
                    <span slot="label" class="validate">群名称:</span>
                    <Input placeholder="限30字" v-model.trim="formData.name" :maxlength="30"></Input>
                </FormItem>
                <FormItem prop="type">
                    <span slot="label" class="validate">群类型:</span>
                    <Select v-model="formData.type" :disabled="isDisabled" @on-change="changeType">
                        <Option :value="item.value" v-for="(item, index) in itemNameList" :key="index">{{ item.label }}</Option>
                    </Select>
                </FormItem>

                <FormItem label="群成员来源范围">
                    <span slot="label" class="validate">群成员来源范围</span>
                    <Select v-model="cityOper.city" transfer style="width: 25%;margin-right:10px" :max-tag-count="1" placeholder="请选择市" filterable @on-change="changeCity">
                        <Option :value="item.value" v-for="(item, index) in cityList" :key="index">{{ item.label }}</Option>
                    </Select>
                    <Select v-model="cityOper.region" transfer style="width: 25%;margin-right:10px" :max-tag-count="1" placeholder="请选择区" :disabled="regionDisabled" filterable @on-change="changeRegion">
                        <Option :value="item.value" v-for="(item, index) in regionList" :key="index">{{ item.label }}</Option>
                    </Select>
                    <Select v-model="cityOper.stree" transfer style="width: 25%;margin-right:10px" :max-tag-count="1" placeholder="请选择街道/乡镇" :disabled="streeDisabled" filterable @on-change="changeStree">
                        <Option :value="item.value" v-for="(item, index) in streeList" :key="index">{{ item.label }}</Option>
                    </Select>
                    <Select v-model="cityOper.project" transfer style="width: 25%" :max-tag-count="1" placeholder="请选择社区" :disabled="projectDisabled" filterable @on-change="changeProject">
                        <Option :value="item.value" v-for="(item, index) in projectList" :key="index">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <FormItem prop="user">
                    <span slot="label" class="validate">{{ "实名加入" }}:</span>
                    <RadioGroup v-model="formData.user">
                        <Radio label="实名"></Radio>
                        <Radio label="网上家园认证"></Radio>
                        <Radio label="不认证"></Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem prop="ischeck">
                    <span slot="label" class="validate">{{ "备注信息" }}:</span>
                    <RadioGroup v-model="formData.ischeck" @on-change="isCheckChange">
                        <Radio label="备注"></Radio>
                        <Radio label="不备注"></Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem v-if="isShowCheck" prop="set">
                    <span slot="label" class="validate">问题设置:</span>
                    <Input v-model.trim="formData.set" placeholder="请设置问题"></Input>
                </FormItem>
            </div>
        </Form>
    </div>
</template>
<script>
export default {
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    ischeck: "备注",
                }
            },
        },
        cityOper: {
            type: Object,
            default: () => {
                return {}
            },
        },
        localStree: {
            type: String,
            default: () => {
                return ""
            },
        },
        isShowCheck: {
            type: Boolean,
            dafault: () => {
                return true
            },
        },
        memberFromScope: {
            type: String,
            default: () => {
                return ""
            },
        },
        isEdit: {
            type: Boolean,
            default: () => {
                return false
            },
        },
        type: {
            type: String,
            default: () => {
                return ""
            },
        },
        // problem: {
        //   type: Object,
        //   default: () => {
        //     return {};
        //   }
        // }
    },

    //  {
    //   formData: {
    //     name: "",
    //     type: "0",
    //     user: "实名",
    //     ischeck: "校验",
    //     set: ""
    //   }
    // },
    data() {
        return {
            itemNameList: [],
            ruleForm: {
                mobile: [{ required: true, message: "手机号码不能为空。", trigger: "blur" }],
                password: [{ required: true, message: "密码不能为空。", trigger: "blur" }],
                captcha: [{ required: true, message: "验证码不能为空。", trigger: "blur" }],
            },
            //   市
            city: "",
            cityList: [], //存放城市列表
            //  区
            region: "",
            regionDisabled: true,
            regionList: [], // 存放区的列表
            //   街道
            stree: "",
            streeDisabled: true,
            streeList: [], //存放街道的列表

            // 区
            project: "",
            projectDisabled: true,
            projectList: [], //存放区的列表
            problem: {},
            chooseLocal: [],
            regionCode: "",
            cityCode: "",

            List: [],
            isDisabled: false,
        }
    },

    methods: {
        changeType(val) {
            console.log(val)
            console.log(this.isShowCheck)
        },
        //   点击了是否检验单选框
        isCheckChange() {
            if (this.formData.ischeck == "备注") {
                this.$emit("changeCheck", true)
                // this.isShowCheck = true;
            } else {
                this.$emit("changeCheck", false)

                // this.isShowCheck = false;
            }
        },
        // 选择城市回调事件
        changeCity(code) {
            this.regionDisabled = false
            let arrCode = code.split("-")
            this.getPulishData(arrCode[arrCode.length - 1], "regionList", "region")
            this.cityCode = arrCode[arrCode.length - 1]
        },
        // 选择区的回调事件
        changeRegion(code) {
            let arrCode = code.split("-")
            if (this.regionCode == "") {
                this.streeDisabled = false
                this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree")
                console.log("进来1")
            } else {
                if (this.regionCode == arrCode[arrCode.length - 1]) {
                    this.streeDisabled = false
                    this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree")
                } else {
                    this.streeList = []
                    this.projectList = []
                    this.stree = []
                    this.project = []
                    this.projectDisabled = true
                    this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree")
                }
            }
            this.regionCode = arrCode[arrCode.length - 1]
        },
        // 选择街道的回调事件
        changeStree(code) {
            let arrCode = code.split("-")
            this.projectDisabled = false
            console.log("进来2")
            this.getPulishData(arrCode[arrCode.length - 1], "projectList", "project")
        },

        changeProject(code) {
        },
        // 接口部分
        // 获取发布范围列表
        getPulishData(code, list, model) {
            if (this.isEdit) {
                this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
                    orgCode: code,
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                }).then(res => {
                    if (res.code == 200) {
                        this[list] = res.dataList.map(item => {
                            return {
                                value: item.orgPath,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                code: item.orgCode,
                            }
                        })
                        if (res.dataList.length == "1") {
                            this[model] = [res.dataList[0].orgPath]
                        }
                        console.log("获取的city表", this[model])
                    }
                })
            } else {
                this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
                    orgCode: code,
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                }).then(res => {
                    if (res.code == 200) {
                        this[list] = res.dataList.map(item => {
                            return {
                                value: item.orgPath,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                code: item.orgCode,
                            }
                        })
                        if (res.dataList.length == "1") {
                            this[model] = [res.dataList[0].orgPath]
                            if (model == "city") {
                                this.cityOper.city = this[model][0]
                                this.changeCity(this.cityOper.city)
                            } else if (model == "region") {
                                this.cityOper.region = this[model][0]
                                this.changeRegion(this.cityOper.region)
                            } else if (model == "stree") {
                                this.cityOper.stree = this[model][0]
                                this.changeStree(this.cityOper.stree)
                            } else if(model == 'project'){
                                this.cityOper.project = this[model][0]
                            }
                        }
                        // console.log("获取的city表", this[model]);
                    }
                })
            }
        },
        getQueryConfig() {
            this.$post("/sendmsg/pc/chat/groupCreate/queryConfigList", {
                businessType: 1,
                oemCode: parent.vue.oemInfo.oemCode,
            }).then(res => {
                this.problem = {}
                this.problem = res.dataList
            })
        },
        async getSelect() {
            await this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: "CHAT_GROUP_TYPE",
                appCode: "",
            }).then(res => {
                let data = res.dataList
                this.itemNameList = []
                data.map(item => {
                    if (item.dictKey != "1" && item.dictKey != "8" && item.dictKey != "9" && item.dictKey != "13" && item.dictKey != "14" && item.dictKey != "15" && item.dictKey != "16" && item.dictKey != "17") {
                        this.itemNameList.push({
                            value: item.dictKey,
                            label: item.dictValue,
                        })
                    }
                })
                if (this.formData.type != "") {
                    this.itemNameList.map(item => {
                        if (item.value == this.formData.type) {
                            if (item.label == "第一工作群" || item.label == "第二工作群" || item.label == "第三工作群") this.isDisabled = true
                        }
                    })
                } else {
                    this.isDisabled = false
                }
            })
        },
    },
    watch: {
        cityOper: {
            handler(val, newVal) {
                if (val) {
                    console.log(this.cityOper)
                }
            },
        },
        memberFromScope: {
            handler(val, newVal) {
                if (val) {
                    let data = val.split("-")
                    switch (data.length) {
                        case 2:
                            this.cityOper.city = data[0] + "-" + data[1]
                            this.changeCity(this.cityOper.city)
                            break
                        case 3:
                            this.cityOper.city = data[0] + "-" + data[1]
                            this.cityOper.region = data[0] + "-" + data[1] + "-" + data[2]
                            this.changeCity(this.cityOper.city)
                            this.changeRegion(this.cityOper.region)
                            break
                        case 4:
                            this.cityOper.city = data[0] + "-" + data[1]
                            this.cityOper.region = data[0] + "-" + data[1] + "-" + data[2]
                            this.cityOper.stree = data[0] + "-" + data[1] + "-" + data[2] + "-" + data[3]
                            this.changeCity(this.cityOper.city)
                            this.changeRegion(this.cityOper.region)
                            this.changeStree(this.cityOper.stree)
                            break
                        case 5:
                            this.cityOper.city = data[0] + "-" + data[1]
                            this.cityOper.region = data[0] + "-" + data[1] + "-" + data[2]
                            this.cityOper.stree = data[0] + "-" + data[1] + "-" + data[2] + "-" + data[3]
                            this.cityOper.project = data[0] + "-" + data[1] + "-" + data[2] + "-" + data[3] + "-" + data[4]
                            this.changeCity(this.cityOper.city)
                            this.changeRegion(this.cityOper.region)
                            this.changeStree(this.cityOper.stree)
                            break
                    }
                }
            },
        },
        // type:{
        //    handler(val){
        //     if(val){
        //       setTimeout(()=>{
        //         this.itemNameList.map(item =>{
        //         console.log(item);
        //         if(item.value == val){
        //           console.log(val);
        //           if(item.label == '第一工作群' || item.label == '第二工作群' || item.label == '第三工作群') this.isDisabled = true
        //         }
        //       })
        //       },100)

        //     }else{
        //       this.isDisabled = false
        //     }
        //   }
        // }
    },

    async created() {
        this.isCheckChange()
        await this.getPulishData("44", "cityList", "city")
        await this.getQueryConfig()
        await this.getSelect()
    },
}
</script>

<style></style>
