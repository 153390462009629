<template>
  <div class="group-div">
    <div class="left-tree">
      <Collapse v-model="collValue">
        <Panel name="1">
          {{'耀华社区'}}
          <div slot="content" class="collapse">
            <div class="tree-search">
              <Input class="tree-input" v-model="searchForm.name" placeholder="请输入关键字进行过滤"></Input>
              <Icon type="ios-search" class="tree-icon" @click="searchList" />
              <Icon type="ios-refresh" class="tree-search" @click="refreshList" />
            </div>
            <Tree :data="treeData" @on-select-change="changeTree"></Tree>
          </div>
        </Panel>
      </Collapse>
    </div>
    <div class="right-table">
      <div>{{'勾选社区下的居民，选中则成为群成员，群成员可以参与发言，也可看到其他居民的发言'}}</div>
      <div class="table-btn">
        <Button type="primary" style="margin-right:10px">全选</Button>
        <Button type="info">反选</Button>
      </div>
      <div class="form-item">
        <Checkbox
          v-for="(item, index) in tableData"
          :key="index"
          style="width:30%;margin:10px"
          v-model="item.status"
          @on-change="checkboxChange(item)"
        >{{ item.name }}</Checkbox>
      </div>
      <!-- <Form
        class="selecForm"
        label-position="left"
        style="
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                text-align:center;
              "
      >
        <FormItem style="width: 30%;" v-for="(item, index) in tableData" :key="index">
          <Checkbox v-model="item.status" @on-change="checkboxChange(item)">{{ item.name }}</Checkbox>
        </FormItem>
      </Form>-->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      treeData: [
        {
          title: "parent 1",
          children: [
            {
              title: "parent 1-1",
              children: [
                {
                  title: "leaf 1-1-1"
                },
                {
                  title: "leaf 1-1-2"
                }
              ]
            },
            {
              title: "parent 1-2",
              children: [
                {
                  title: "leaf 1-2-1"
                },
                {
                  title: "leaf 1-2-1"
                }
              ]
            }
          ]
        }
      ],
      //   折叠面板绑定数据
      collValue: "1",
      //   树形结构搜索框
      searchForm: {
        name: ""
      },
      tableData: [
        { status: false, name: "1" },
        { status: false, name: "1" },
        { status: false, name: "1" },
        { status: false, name: "1" },
        { status: false, name: "1" },
        { status: false, name: "1" },
        { status: false, name: "1" },
        { status: false, name: "1" },
        { status: false, name: "1" },
        { status: false, name: "1" }
      ] //
    };
  },
  methods: {
    //   点击修改按钮
    searchList() {},
    //   点击重置按钮
    refreshList() {},
    // 选择树节点回调事件
    changeTree(value) {
      console.log(value);
    },
    // 选择checkbox回调事件
    checkboxChange() {}
  }
};
</script>

<style scoped lang="less">
.group-div {
  height: 750px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .left-tree {
    width: 20%;
    height: 100%;
    .collapse {
      height: 650px;
      .tree-search {
        display: flex;
        justify-content: flex-start;
        .tree-input {
          width: 70%;
        }
        .tree-icon {
          width: 15%;
          font-size: 23px;
          padding-top: 5px;
        }
        .tree-search {
          width: 15%;
          font-size: 25px;
          padding-top: 5px;
        }
      }
    }
  }
  .right-table {
    padding: 0 10px;
    width: 80%;
    height: 100%;
    .table-btn {
      margin: 10px 0;
    }
    .form-item {
      //   border: 1px solid red;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      text-align: left;
    }
  }
}
</style>